@import '../../../styles/variables.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .reader {
    width: 100%;
    .page {
      width: 100%;
      display: flex;
      justify-content: center;
      box-shadow: 0px 10px 28px 0px rgba(235, 236, 237, 1);
    }
  }
  .nav {
    margin: 20px;
    min-width: 130px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    z-index: 3;
    border-radius: $radius;
    box-shadow: 0px 10px 28px 0px rgba(235, 236, 237, 1);
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $dark;
      &:hover {
        color: $primary;
        transform: scale(1.2)
      }
    }
    p {
      margin: 0px;
      font-size: 14px;
    }
  }
}
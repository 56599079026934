@import '../../../styles/variables.scss';

.filters {
  @include anim-fade;
  transition: all 0.1s ease-in-out;
  position: fixed;
  background-color: $primary;
  top: 61px;
  left: 5%;
  border-radius: $radius;
  border: 1px solid $white;
  width:108px;
  z-index: 300;
  display: flex;
  color: $white;
  font-size: 14px;
  @media only screen and (min-width: $md-screen) {
    left: 2%;
  }
  @media only screen and (min-width: $lg-screen) {
    left: 5%;
  }

  &.loading {
    cursor: wait;
    pointer-events: none;
  }

  &.disabled {
    opacity: 0;
    pointer-events: none;
  }
  &.isOpen {
    width: calc((220px * 4) + 109px);
    &.library {
      width: calc((220px * 5) + 109px);
    }
  }
  .btn-filters {
    min-width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.border {
      min-width: 67px;
      width: 67px;
      justify-content: space-between;
      box-shadow: -8px 4px 10px 0px rgba(25, 34, 67, 0.15);
    }
    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      color: $white;
      font-size: 14px;
    }
  }
  .filter {
    @include transition;
    width: 0px;
    opacity: 0;
    &.isOpen {
      width: 220px;
      min-width: 220px;
      opacity: 1;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.border {
      border-right: 1px solid $white;
    }
    button {
      border-radius: 30px;
      margin-right: 5px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: rgba($white, 0.2)
      }
      &.btn-radio {
        width: 100%;
        opacity: 0.5;
        &.isActive {
          opacity: 1;
        }
        &:hover {
          background-color: rgba($white, 0);
          opacity: 1;
        }
      }
    }
    p {
      padding-left: 10px;
      overflow: hidden;
      max-height: 34px;
      font-family: "Ubuntu-Regular";
      color: $white;
      margin: 0px;
    }
    input {
      font-family: "Ubuntu-Regular";
      background-color: transparent;
      outline: none;
      border: 0px;
      width: 175px;
      color: #FFFFFF;
      font-size: 14px;
      padding: 8px;
      border-radius: $radius;
      &:focus , &:hover {
        background: rgba($dark, 0.1);
      }
      &::placeholder {
        color: rgba($white, 0.6);
      }
    }
    .search {
      position: absolute;
      background-color: $primary;
      width: 220px;
      top: 39px;
      border-right: 1px solid $white;
      input {
        background: rgba($dark, 0.1);
        min-width: calc(100% - 10px);
        border-radius: $radius;
        padding: 8px;
        margin: 5px;
      }
    }
    .toggle {
      display: flex;
      padding: 5px 0px;
    }
    .list {
      position: absolute;
      z-index: 3000;
      top: 39px;
      width: 220px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0px 10px;
      background-color: $primary;
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
      border-right: 1px solid $white;
      @include transition;
      .tags-count {
        padding: 10px 0px;
        margin: 10px 0px;
      }
      &.selected-list {
        top: 39px;
        overflow-y: hidden;
      } 
      &::-webkit-scrollbar {
        height: 10px;
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba($white, 0.5);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgba($white, 0.8);
      }
      .list-content {
        max-height: 200px;
      }
      p {
        color: $white;
      }
    }
  }
  .item {
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0px;
    opacity: 0.6;
    .option {
      width: 100%;
      display: flex;
      align-items: center;
      svg {
        min-width: 13px;
      }
      .icon {
        display: flex;
        justify-content: center;
        svg {
          transform: translateX(5px);
        }
      }
    }
    .color-circle {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-left: 10px;
      opacity: 0;
    }
    &.isActive {
      opacity: 1;
      .color-circle {
        opacity: 1;
      }
    }
  }
}
@import '../../../styles/variables.scss';

.nav {
  @include transition;
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: center;
  top:0px;
  left:0px;
  z-index: 1;
  height: 60px;
  font-size: 16px;
  font-family: "Anybody-SemiBold";
  transform: translateY(-120px);
  background-color: $white;
  @media only screen and (min-width: $md-screen) {
    height: 80px;
    box-shadow: $card-shadow;
  }
  &.nav-is-active {
    transform: translateY(0px);
    box-shadow: $card-shadow;
  }
  .content {
    @include content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      text-decoration: none;
    }
    h1 {
      margin: 0px;
      color: $dark;
      font-family: "Anybody-Bold";
      text-transform: uppercase;
    }
    .links {
      display: none;
      text-transform: uppercase;
      @media only screen and (min-width: $md-screen) {
        display: block;
      }
      a {
        @include transition;
        padding: 10px 20px;
        border-radius: $radius;
        color: rgba($dark , 0.25);
        text-decoration: none;
        &.is-active {
          color: $dark;
        }
      }
    }
    .icons {
      display: flex;
      justify-content: flex-end;
      @media only screen and (min-width: $md-screen) {
        min-width: 102px;
        a {
          margin-left: 15px;
        }
      }
    }
    .mobile-toggle {
      display: block;
      @media only screen and (min-width: $md-screen) {
        display: none;
      }
    }
    .logout {
      display: none;
      justify-content: flex-end;
      margin-left: 20px;
      @media only screen and (min-width: $md-screen) {
        display: flex;
      }
    }
  }

}

.links-mobile {
  @include transition;
  display: flex;
  z-index: 3000;
  width: 60%;
  min-height: 100vh;
  flex-direction: column;
  position: fixed;
  background-color: $white;
  box-shadow: 0px 13px 13px 0px rgba(156,167,177,0.80);
  transform: translateX(110%);
  right:0;
  top: 60px;
  &.isActive {
    transform: translateX(0);
  }
  @media only screen and (min-width: $md-screen) {
    display: none;
  }
  a, button {
    margin: 10px $margin-md;
    padding: 10px;
    border-radius: $radius;
    font-size: 14px;
    font-family: "Anybody-SemiBold";
    color: rgba($dark , 0.5);
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    &.isActive {
      color: $dark;
    }
  }
}

.logo {
  @include transition;
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0px;
  left:0px;
  z-index: 0;
  height: 80px;
  font-size: 16px;
  font-family: "Anybody-SemiBold";
  transform: translateY(-80px);
  background-color: $bg-color;
  &.logo-is-active {
    transform: translateY(0px);
  }
  .content {
    @include content;
    img {
      width: 160px;
    }
  }
}

.filters {
  @include transition;
  position: relative;
  z-index: 300;
  transform: translateY(-120px);
  &.is-active {
    transform: translateY(0px);
  }
}

@import '../../../styles/variables.scss';

.container {
  width: 500px;
  h2 {
    text-align: center;
    margin: 0px 0px 20px 0px ;
  }
  .input-file {
    width: 200px;
  }
  .error {
    margin: 0px;
    color: $danger;
    min-height: 20px;
    padding-top: 3px;
    font-size: 12px;
  }
  .actions {
    button {
      font-family: "AnyBody-SemiBold";
      text-transform: uppercase;
      &.cancel {
        margin-left: 20px;
        padding: 0px;
        border-bottom: 2px solid transparent;
        &:hover {
          border-bottom: 2px solid $primary;
        }
      }
    } 
  }
  .file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
    margin: 10px 0px;
    text-transform: none;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    font-size: 14px;
    box-shadow: 0px 10px 28px #e0e0e0;
    color: $primary;
    border-radius: 3px;
    .link {
      display: flex;
      align-items: center;
      p {
        margin: 0px;
        color: $dark;
      }
      svg {
        margin-right: 10px;
        fill: $primary;
      }
    }
    .delete {
      display: flex;
      align-items: center;
      font-family: "Ubuntu-Regular";
      p {
        color: red;
        margin: 0px;
      }
      svg {
        margin-right: 5px ;
        fill: red;
      }
    }
  }
}
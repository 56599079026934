@import '../../styles/variables.scss';

.page-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0px 0px 150px;
  .loader {
    transform: translateX(-100px)
  }
  .header {
    position: absolute;
    width: 100%;
    background: $bg-color;
    background: linear-gradient(180deg, rgba($bg-color,1) 0%, rgba($bg-color,1) 80%, rgba($bg-color,0) 100%);
    pointer-events: all;
    .content {
      position: relative;
      width: calc(80% - 200px);
      z-index: 103;
      padding-right: 15px;
      top: 10px;
      height: 60px;
      margin: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-transform: uppercase;
      z-index: 105;
      .select {
        pointer-events: all;
        width: 300px;
        margin: 10px;
      }
      h2 {
        font-family: "AnyBody-SemiBold";
        color: rgba($dark , 0.3);
        border-bottom: 2px solid transparent;
        margin-right: 20px;
        cursor: pointer;
        @include transition;
        &:hover {
          border-bottom: 2px solid $primary;
        }
        &.active {
          color: $dark;
          border-bottom: 2px solid $primary;
        }
      }
      p {
        min-width: 100px;
        text-align: right;
        color: $primary
      }
    }
  }
  .container {
    width: calc(100% - 150px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    overflow: auto;
    margin-top: 70px;
    z-index: 104;
    height: calc(100vh - 220px);
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $primary;
    }
    .content {
      // min-height: 200vh;
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
    /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $primary;
      }
      overflow-x: hidden;
      padding:20px 0px;
      .loader {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
  .dates-list {
    position: absolute;
    bottom: 20px;
    display: flex;
    left: -190px;
    margin-left: 190px;
    z-index: 0;
    overflow: hidden;
    .year {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      pointer-events: none;
      @include transition;
      .bar {
        border-left: 1px solid rgba($dark , 0.2);
        height: calc(100vh - 240px);
      }
      p {
        color: rgba($dark , 0.3);
        pointer-events: all;
        font-size: 14px;
        cursor: pointer;
        @include transition;
        &:hover {
          color: rgba($dark , 1);
        }
      }
      &.new {
        .bar {
          border-left: 1px solid $primary;
        }
        p {
          color: $primary;
          &:hover {
            color: rgba($dark , 1);
          }
        }
      }
    }
  }
}



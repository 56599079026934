@import '../../../styles/variables.scss';

.loader {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: lighten($primary ,30%);
  border-radius: 5px;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-name: rotate;
  margin-left: 12px;
}

  @keyframes rotate {
    0% {
      transform: rotateX(0) rotateY(0);
      }
    50% {
      transform: rotateX(-180deg) rotateY(0);
    }
    100% {
      transform: rotateX(-180deg) rotateY(-180deg);
    }
  }
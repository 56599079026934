@import '../../styles/variables.scss';

.container {
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/login.jpg') no-repeat center center fixed;
  background-size: cover;
  overflow-y: auto;
  .loader {
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .topics {
    width: 600px;
    background: #FFFFFF;
    box-shadow: 0px 10px 28px $dark;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    h1 {
      color: $primary;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
    }
    .search {
      width: 450px;
      .list {
        margin: 20px 0px;
        .topic {
          @include bold;
          display: flex;
          justify-content: space-between;
          background-color: $primary;
          color: $white;
          padding: 10px 8px 10px 15px;
          border-radius: $radius;
          margin-bottom: 10px;
          p {
            color: $white;
            margin: 0px;
          }
          button {
            display: flex;
            padding: 0px;
            svg {
              color: $white;
              font-size: 20px;
            }
          }
        }
      }
    }
    .dates {
      border-top: 1px solid #EDEDED;
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 30px 0px;
      .col {
        width: calc(30% - 5px);
        display: flex;
        align-items: center;
        padding: 10px;
        &.count {
          width: calc(20% - 5px);
        }
        p {
          margin: 0px 5px;
        }
        svg {
          color: rgba($dark, 0.6)
        }
      }
    }
    .btn {
      @include bold;
      color: $white;
      text-transform: uppercase;
      text-align: center;
      &.disabled {
        background-color: rgba($dark, 0.2);
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
}
@import '../../styles/variables.scss';

.page-container {
  width: 100%;
  height: 100vh;
  .message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

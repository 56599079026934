@import '../../../../../styles/variables.scss';

.container {
  position: relative;
  .input {
    position: absolute;
    min-width: 141px;
    top: -53px;
    right: 0px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    button {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      padding: 1px 4px;
      margin: 5px;
      background-color: rgba($dark , 0.4);
      width: 25px;
      height: 25px;
      border-radius: 20px;
      &:hover {
        background-color: rgba($danger , 0.8);
      }
    }
    img {
      height: 119px;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
    }
  }
}
@import '../../../../../styles/variables.scss';

.container {
  @include transition;
  position: absolute;
  top: 100px;
  height: calc(100vh - 110px);
  z-index: -10;
  width: 100px;
  transform: translateX(-220px);
  padding: 40px 20px;
  &.is-open {
    transform: translateX(0px);
  }
  .dates {
    display: flex;
    align-items: center;
    width: 200px;
    font-size: 12px;
    margin: 20px 18px;
    p {
      margin: 0px;
      color: $primary;
      font-weight: bold;
    }
    svg {
      color: rgb(196, 196, 196);
    }
  }
}
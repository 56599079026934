@import '../../../../styles/variables.scss';

.abstract {
  h1 {
    font-family: "Ubuntu-Regular";
    font-weight: bold;
    margin: 0px;
    font-size: 48px;
    line-height: 55px;
  }
  .info {
    display: flex;
    p {
      margin-right: 20px;
      &:not(:first-child) {
        padding-left: 20px;
        border-left: 1px solid rgba(77,84,111, 0.2)
      }
    }
  }
  .title {
    margin-bottom: 20px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    .col {
      width: calc(50% - 10px )
    }
  }
}
@import '../../../styles/variables.scss';

 
.container {
  @include transition;
  @include box;
  top: 110px;
  right: 30px;
  max-height: calc(100% - 130px);
  overflow-y: auto;
  position: absolute;
  padding: 20px;
  width: 380px;
  z-index: 200;
  transform: translateX(440px);
  &.active {
    transform: translateX(0px);
  }
  .close {
    @include btn;
    position: absolute;
    right: 6px;
    top: 10px;
    font-size: 20px;
    color: rgba($dark, 0.5);
    &:hover {
      color: $dark;
    }
  }
  label {
    color: rgba($dark, 0.5);
    text-transform: uppercase;
  }
  .icon {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
    svg {
      color: $primary;
      margin-right: 5px;
      transform: translateY(-2px);
      font-size: 20px;
      min-width: 20px;
    }
    p {
      margin: 0px;
      span {
        @include bold;
      }
    }
  }
  .infos {
    border-top: 1px solid #DCDEE6;
    padding: 20px 0px;
    .filters {
      background-color: $primary;
      padding: 8px;
      font-size: 14px;
      border-radius: $radius;
      color: $white;
      margin-bottom: 20px;
      span {
        @include bold;
      }
    }
    .label {
      margin: 30px 0px 10px 0px;
      display: flex;
      align-items: center;
      .puce {
        width: 14px;
        height: 14px;
        border-radius: 14px;
        margin-right: 6px;
        background-color: $dark;
        &.primary {
          background-color: $primary;
        }
      }
    }
    p {
      margin: 0px 0px 5px 0px;
      font-size: 14px;
    }
    .rates {
      p {
        margin-bottom: 10px;
        span {
          @include bold;
          color: $primary;
        }
      }
      .rate {
        position: relative;
        width: 100%;
        height: 10px;
        &:hover .tooltip {
          opacity: 1;
        }
        &:hover .bar {
          background-color: $primary;
        }
        .bar {
          @include transition;
          height: 5px;
          background-color: rgba($primary,0.4);
          margin-bottom: 5px;
        }
        .tooltip {
          @include transition;
          @include box;
          position: absolute;
          right: 0px;
          transform: translateY(-30px);
          opacity: 0;
          z-index: 300;
          p {
            margin: 0px;
            font-size: 14px;
            padding: 5px 10px;
            border-radius: $radius;
            span {
              @include regular;
              color: rgba($dark, 0.5);
            }
          }
        }
      }
    } 
  }
}

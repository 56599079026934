@import '../../styles/variables.scss';

.page-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .header {
    position: absolute;
    width: 100%;
    z-index: 1;
    background: $bg-color;
    background: linear-gradient(180deg, rgba($bg-color,1) 0%, rgba($bg-color,1) 80%, rgba($bg-color,0) 100%);
    pointer-events: none;
    .content {
      position: relative;
      width: 90%;
      padding-right: 10px;
      top: 10px;
      height: 80px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      pointer-events: all;
      .links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
          display: flex;
          align-items: center;
          p {
            @include transition;
            font-family: "Anybody-SemiBold";
            text-transform: uppercase;
            text-decoration: none;
            color: $dark;
            border-bottom: 2px solid transparent;
            &:hover {
              border-bottom: 2px solid $primary;
            }
          } 
          svg {
            margin-right: 4px;
          }
        }
      }

      .actions {
        button {
          font-family: "Anybody-SemiBold";
          text-transform: uppercase;
          margin-left: 10px;
        }
      }

      h2 {
        font-family: "AnyBody-SemiBold";
        color: rgba($dark , 0.3);
        border-bottom: 2px solid transparent;
        margin-right: 20px;
        cursor: pointer;
        @include transition;
        &:hover {
          border-bottom: 2px solid $primary;
        }
        &.active {
          color: $dark;
          border-bottom: 2px solid $primary;
        }
      }
      p {
        color: $primary
      }
    }
  }
  .container {
    width: 100%;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    scroll-behavior: smooth;
    .content {
      max-width: 850px;
      width: 70%;
      padding-top: 80px;
      h1 {
        font-family: "Ubuntu-Regular";
        font-weight: bold;
        margin: 0px;
        font-size: 48px;
        line-height: 55px;
      }
      h2 {
        text-transform: uppercase;
        color: $primary;
        font-size: 14px;
      }
      .keywords {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 40px;
        p {
          background-color: $primary;
          margin: 0px 10px 10px 0px;
          padding: 6px 15px ;
          min-width: 120px;
          font-size: 14px;
          border-radius: 20px;
          color: $white;
        }
      }
      .description {
        label {
          display: inline-block;
          font-weight: bold;
          font-size: 16px;
          margin-top: 20px;
        }
        font-size: 14px;
        margin-bottom: 40px;
      }
    }
  }
}


@import '../../../styles/variables.scss';

.container {
  @include transition;
  @include btn;
  width: 100%;
  padding: 10px;
  .labels {
    display: flex;
    justify-content: space-between;
    p {
      margin: 0px;
      font-size: 14px;
      color: rgba($dark , 0.5);
      &.name {
        font-weight: bold;
        color: rgba($dark , 0.8);
      }
    }
  }
  .bar {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
    .range-bar {
      height: 4px;
      border-radius: 4px;
      background-color: $primary;
    }
  }

}
@import '../../styles/variables.scss';

.login {
  overflow: auto;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  display: flex;
  font-family: "Ubuntu-Regular";
  label {
    font-family: "Ubuntu-Regular";
  }
  h1 {
    text-transform: uppercase;
    font-size: 21px;
    font-family: "Anybody-Bold";
  }
  input {
    font-family: "Ubuntu-Regular";
    &.primary {
      border: none ;
      height: 50px;
      border-radius: 3px;
      padding-left: $margin-md;
      color: $dark;
      background-color: $white;
      box-shadow: 0px 10px 28px 0px rgba(156,167,177,0.30);
      font-size: 16px;
      margin-top: 4px;
      transition: all 0.2s ease-in-out;
      &:focus {
        outline: none;
      }
    }
  }
  .btn {
    border: none;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: $radius;
    font-size: 14px;
    text-transform: uppercase;
    &.primary {
      color: $white;
      text-decoration: none;
      background-color: $primary;
      &:hover {
        transform: scale(1.03)
      }
    }
  }
  .col-form {
    width: 100%;
    min-height: 100%;
    padding: $margin-md;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media only screen and (min-width: $md-screen) {
      width: 45%;
    }
    .title {
      text-transform: uppercase;
      text-align: center;
      font-family: "Anybody-Bold";
      line-height: 90%;
      .name {
        font-size: 31px;
        color: $primary;
        letter-spacing: 4px;
        font-family: "Anybody-Bold";
      }
      span {
        font-size: 12px;
        color: $dark;
        font-family: "Ubuntu-Regular";
      } 
    }
    .form-reset {
      &.message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          margin: $margin-md
        }
      }
    }
    form {
      &.form-reset {
        @include animation-mixin-y(slideInLogin, 50px, 0);
        animation-name: slideInLogin;
        animation-duration: 0.2s;
      }
      &.form-login {
        @include animation-mixin-y(slideIn, 50px, 0);
        animation-name: slideIn;
        animation-duration: 0.2s;
      }
      width: 90%;
      max-width: 400px;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .eye {
        svg {
          float: right;
          transform: translate(-20px , -67px)
        }
        &-reset {
          svg {
            float: right;
            transform: translate(-20px , -38px)
          }
        }
      }
      .btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn-reset {
          margin:$margin-md;
          color:$dark;
          text-decoration: underline;
        }
      }
    }
    .sso {
      display: flex;
      flex-direction: column;
      .btn {
        margin-top: 10px;
      }
    }
  }
  .col-img {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: block;
      width: 55%;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../../assets/images/login.jpg');
    }
  }
}

.sso-loader {
  height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
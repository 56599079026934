@import '../../../styles/variables.scss';

.btn {
  @include transition;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  font-size: 14px;
  font-family: "Ubuntu-Regular";

  &.radius {
    border-radius: $radius;
  }
  &.danger {
    color: $white;
    text-decoration: none;
    background-color: $danger;
    border-radius: 3px;
    &:hover {
      transform: scale(1.03)
    }
    &.outline {
      border: 1px solid $danger;
      background-color: $white;
      color:$danger;
      &:hover {
        transform: scale(1);
        background-color: rgba($danger , 0.2);
      }
    }
  }
  &.primary {
    color: $white;
    text-decoration: none;
    background-color: $primary;
    border-radius: 3px;
    &:hover {
      transform: scale(1.03)
    }
    &.outline {
      border: 1px solid $primary;
      background-color: $white;
      color:$primary;
      &:hover {
        transform: scale(1);
        background-color: rgba($primary , 0.2);
      }
    }
  }
}
@import '../../../styles/index.scss';

.toaster {
  @include transition;
  position: fixed;
  bottom: 0;
  right: calc(50% -  170px);
  width: 300px;
  padding: 10px;
  margin: 20px;
  z-index: 3000;
  border-radius: 3px;
  transform: translateY(100px);
  box-shadow: $shadow;
  background-color: $primary;
  color: $white;
  p {
    margin: 0px;
    text-align: center;
    color: $white;
  }
  // &.success {
  //   background-color: $success;
  //   color: $white;
  // }
  &.error {
    background-color: $danger;
    color: $white;
  }
  &.isActive {
    transform: translateY(0px);
  }
}
@import '../../../../styles/variables.scss';

.section {
  margin-top: 20px;
  padding: 10px 0px;
  min-height: 600px;
  border-top: 1px solid rgba(25, 34, 67, 0.08);
  p {
    font-size: 14px;
  }
  h2 {
    margin-bottom: 20px;
    font-family: "Ubuntu-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
  h3 {
    font-size: 14px;
    font-family: "Ubuntu-Regular";
  }
  .select {
    margin-bottom: 30px;
  }
}
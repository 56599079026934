@import '../../../../../styles/variables.scss';

a {
  text-decoration: none;

}
 
.container {
  @include transition;
  @include box;
  width: 100%;
  animation: fadeIn 0.3s linear;
  animation-fill-mode: both;
  color: $dark;
  display: flex;
  align-items: center;
  p {
    margin: 0px;
    font-size: 15px;
  }
  .icon {
    color: $primary;
    width: 42px;
    padding: 15px 20px;
    transform: translateY(1px);
  }
  .title {
    width: calc(70% - 84px);
    padding: 15px 20px;
    &.preview {
      width: calc(70% - 42px);
    }
    p {
      margin: 0px;
      font-size: 15px;
      @include transition;
      &:hover {
        color: $primary; 
      }
    }
  }
  .info {
    width: calc(15%);
    padding: 10px 20px;
    height: 100%;
    text-align: center;
    border-left: 1px solid rgba(77,84,111, 0.2)
  }
  .close {
    width: 42px;
    padding: 8px 0px;
    height: 100%;
    text-align: center;
    border-left: 1px solid rgba(77,84,111, 0.2);
    &.deleted {
      pointer-events: none;
      svg {
        animation: rotation 1s infinite linear;
        fill:rgba(77,84,111, 0.4);
      }
    }
    svg {
      fill:rgba(77,84,111, 0.4);
    }
    &:hover {
      svg {
        fill: $danger;
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@import '../../../styles/variables.scss';

.container {
  position: relative;
  height: 100vh;
  width: 100%;
  @include transition;
  svg {
    position: absolute;
    transform-origin: 0 0;
    line {
      display: none;
    }
  }
  .node {
    position: absolute;
    transform-origin: 0 0;
    pointer-events: none;
    // &:hover {
    //   background-color: red;
    // }
    &.not-active {
      opacity: 0.3;
    }
  }
}

.graph {
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  canvas {
    max-width: 100%;
  }
}
@import '../../../styles/variables.scss';

.date-picker {
  margin-top: 5px;
  border: 0px solid $light-grey;
  transition: all 0.2s ease-in-out;
  background: $white;
  box-shadow: 0px 10px 28px rgb(224, 224, 224);
  border-radius: 3px;
  height: 45px;
  padding: 16px;
  display: flex;
  align-items: center;
  .MuiInput {
    background-color: $white;
    font-size: 14px;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormLabel-root {
    font-family: "Ubuntu-Regular";
    font-size: 14px;
  }
  .MuiPickersDay-daySelected {
    color: #fff;
    font-weight: 500;
    background-color: $dark;
  }.MuiIconButton-root {
    padding: 0px;
  } 
  svg {
    fill: rgba($dark , 0.5 );
    &:hover {
      fill: $primary;
    }
  }
}
.error-message {
  margin: 0px;
  color: $danger;
  min-height: 20px;
  padding-top: 3px;
  font-size: 12px;
}
@import '../../../styles/variables.scss';

.map {
  transform: scale(1);
  @include animation-scale(slideInScale, 0, 1);
  animation: slideInScale 0.2s linear ;
  animation-fill-mode: forwards;
  path , circle {
    cursor: pointer;
  }
  circle {
    @include transition;
    stroke-width: 0;
    stroke: $dark;
    &:hover {
      stroke-width: 1;
    }
  }
}
@import '../../../styles/variables.scss';

.dataviz-loader {
  @include transition;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($bg-color , 0.6 );
  z-index: 3000;
  svg {
    width: 120px;
    animation: rotating 1s linear infinite;
  }
  opacity: 0;
  pointer-events: none;
  &.is-active {
    opacity: 1;
    pointer-events: none;
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
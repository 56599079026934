@mixin page-container {
  top: 80px;
  position: absolute;
  left: 0;
  width: 100vw;
  z-index: -1;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  opacity: 1;
}

@mixin content {
  position: relative;
  width: 90%;
  @media only screen and (min-width: $md-screen) {
    width: 96%;
  }
  @media only screen and (min-width: $lg-screen) {
    width: 90%;
  }
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin bold {
  font-family: "Anybody-Bold";
}

@mixin regular {
  font-family: "Ubuntu-Regular";
}


@mixin slideInLogin {
	@include animation-mixin-y(slideIn, 30px, 0);
	animation-name: slideIn;
  animation-duration: 0.2s;
}

@mixin anim-fade {
  @include animation-fade(fade);
  opacity: 0;
	animation-name: fade;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

@mixin anim-rate {
  @include animation-width(width-anime);
  width: 0%;
  color: rgba($dark , 0);
	animation-name: width-anime;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

@mixin animation-mixin-x($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateX($from); opacity: 0;}
    100% {transform: translateX($to); opacity: 1;}
  }
}

@mixin animation-scale($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: scale($from); opacity: 0;}
    100% {transform: scale($to); opacity: 1;}
  }
}

@mixin animation-mixin-y($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateY($from); opacity: 0;}
    100% {transform: translateY($to); opacity: 1;}
  }
}

@mixin animation-fade($name) {
  @keyframes #{$name} {
    0% { opacity: 0;}
    100% { opacity: 1;}
  }
}

@mixin animation-width($name) {
  @keyframes #{$name} {
    0% { width: 0% ; color: rgba($dark , 0);}
    100% { width: 100% ; color: rgba($dark , 1);}
  }
}

@mixin btn {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  outline: none;
  border: none;
}

@mixin box {
  @include transition;
  background: #FFFFFF;
  box-shadow: 0px 10px 28px #EBECED;
  border-radius: 3px;
  margin-bottom: 20px;
}

@mixin dashboard-card {
  width: calc(33% - 15px);
  height: 213px;
  padding: 0px 20px;
  @include box;
}

@mixin animation-scale($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: scale($from); opacity: 0;}
    100% {transform: scale($to); opacity: 1;}
  }
}

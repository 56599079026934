@import '../../../styles/variables.scss';

.container {
  @include transition;
  @include box;
  width: calc(33.33% - 10px);
  margin-bottom: 0px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:not(:last-child) {
    margin-right: 20px;
  }
  &:hover {
    transform: scale(1.05)
  }
  p {
    margin: 0px;
    font-size: 14px;
  }
  .info {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    p {
      color: rgba($dark , 0.5);
    }
  }
}

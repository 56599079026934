@import '../../../styles/variables.scss';

.container {
  position: relative;
  .list {
    width: 100%;
    margin: 20px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .tag {
      @include transition;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgba($dark , 0.2);
      color: $dark;
      padding: 5px 10px;
      border-radius: $radius;
      margin-right: 10px;
      margin-bottom: 10px;
      font-size: 14px;
      font-family: "Ubuntu-Regular";
      p {
        margin: 0px;
        line-height: normal;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        transform: translateX(5px);
        svg {
          @include transition;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
  .add-tag {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 38px;
    @media only screen and (min-width: $md-screen) {
      float: right;
      display: flex;
      align-items: center;
      flex-direction: row;
      min-height: 38px;
    }
    .btn-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .btn {
        margin-top: 8px;
        margin-left: 0px;
        width: calc(50% - 4px);
        @media only screen and (min-width: $md-screen) {
          width: auto;
          margin-top: 6px;
          margin-left: 10px;
        }
      }
    }
    .input {
      max-height: 36px;
    }
  }
  .btn {
    margin-top: 4px;
    margin-left: 10px;
  }
  
}

.modale {
  width: 400px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    margin-top: 20px;
  }
}
@import '../../../styles/variables.scss';

.textarea {
  width: 100%;
  max-width: 100%;
  textarea {
    @include transition;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-family: "Ubuntu-Regular";
    min-height: 100px;
    padding: 14px;
    font-size: 14px;
    margin-top: 5px;
    border: 0px solid $light-grey;
    background: $white;
    box-shadow: 0px 10px 28px rgb(224, 224, 224);
    border-radius: 3px;
    &:focus {
      outline: none;
      border: 1px solid $primary;
      color: $primary;
    }
  }
  .error-message {
    margin: 0px;
    color: $danger;
    min-height: 20px;
    padding-top: 3px;
    font-size: 12px;
  }
}
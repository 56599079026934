@import '../../../styles/variables.scss';

a {
  text-decoration: none;
}
 
.container {
  @include transition;
  @include box;
  width: 100%;
  animation: fadeIn 0.3s linear;
  animation-fill-mode: both;
  color: $dark;
  display: flex;
  align-items: center;
  &:hover {
    box-shadow: 0px 8px 20px rgb(199, 199, 199);
  }
  p {
    margin: 0px;
    font-size: 15px;
  }
  .icon {
    color: $primary;
    width: 42px;
    padding: 15px 20px;
    transform: translateY(1px);
  }
  .title {
    width: calc(70% - 42px);
    padding: 15px 20px;
    p {
      margin: 0px;
      font-size: 15px;
    }
  }
  .info {
    width: calc(15%);
    padding: 10px 20px;
    height: 100%;
    text-align: center;
    border-left: 1px solid rgba(77,84,111, 0.2)
  }
}


// Keyframe animation
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(60px)
  }
  100% {
    opacity: 1;
    transform: translateY(0px)
  }
}
@import '../../../../../styles/variables.scss';

.custom-range {
  margin-left: 65px;
  .rc-slider-track {
    position: absolute;
    left: 4;
    height: 4px;
    border-radius: 6px;
    background-color: $primary;
  }
  .rc-slider-handle {
    border: solid 2px $primary;
    &.rc-slider-handle:active {
      border-color: $primary;
      box-shadow: 0 0 10px $primary;
      cursor: grabbing;
    }
  }
  .rc-slider-dot-active {
    border-color: $primary;
  }
  .rc-slider-mark-text {
    transform: translateX(-50px);
    color: rgb(196, 196, 196);
    &.rc-slider-mark-text-active {
      color: $primary;
    }
  }
}
@import '../../styles/variables.scss';

.page-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .header {
    position: absolute;
    width: 100%;
    z-index: 1;
    background: $bg-color;
    background: linear-gradient(180deg, rgba($bg-color,1) 0%, rgba($bg-color,1) 80%, rgba($bg-color,0) 100%);
    pointer-events: none;
    .content {
      position: relative;
      width: 80%;
      padding-right: 15px;
      top: 10px;
      height: 100px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      pointer-events: all;
      .links {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .counter {
        display: flex;
        align-items: center;
        button {
          margin-left: 20px;
          font-family: "AnyBody-SemiBold";
          text-transform: uppercase;
        }
      }
      h2 {
        font-family: "AnyBody-SemiBold";
        color: rgba($dark , 0.3);
        border-bottom: 2px solid transparent;
        margin-right: 20px;
        cursor: pointer;
        @include transition;
        &:hover {
          border-bottom: 2px solid $primary;
        }
        &.active {
          color: $dark;
          border-bottom: 2px solid $primary;
        }
      }
      p {
        color: $primary
      }
    }
  }
  .container {
    width: 100%;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    .content {
      max-width: 80%;
      padding-top: 100px;
      .loader {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}


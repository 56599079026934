@import '../../styles/variables.scss';

.page-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px 0px;
  overflow-y: auto;
  .row {
    width: 83%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    a {
      color: $dark;
      h2 {
        text-decoration: underline;
        &:hover {
          color: $primary;
        }
      }
    }
    .col {
      width: calc(50% - 20px);
      h2 {
        text-transform: uppercase;
        font-size: 18px;
        svg {
          color: $primary;
          margin-right: 5px;
          transform: translateY(5px);
        }
      }
      &-top {
        display: flex;
        justify-content: space-between;
        width: calc(50% - 20px);
        .top {
          width: calc(50% - 20px);
          .top-list {
            @include transition;
            @include box;
            padding: 10px;
          }
        }
      }
      .card-list {
        display: flex;
        justify-content: flex-start;
        height: 150px;
      }
      .map {
        width: 100%;
        height: 380px;
      }
    }
  }
}
@import '../../styles/variables.scss';

.page-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .header {
    position: absolute;
    width: 100%;
    z-index: 1;
    background: $bg-color;
    background: linear-gradient(180deg, rgba($bg-color,1) 0%, rgba($bg-color, 0.5) 80%, rgba($bg-color,0) 100%);
    pointer-events: none;
    .content {
      position: relative;
      width: 90%;
      padding-right: 10px;
      top: 10px;
      height: 80px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      pointer-events: all;
      .links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
          display: flex;
          align-items: center;
          p {
            @include transition;
            font-family: "Anybody-SemiBold";
            text-transform: uppercase;
            text-decoration: none;
            color: $dark;
            border-bottom: 2px solid transparent;
            &:hover {
              border-bottom: 2px solid $primary;
            }
          } 
          svg {
            margin-right: 4px;
          }
        }
      }

      .actions {
        button {
          font-family: "Anybody-SemiBold";
          text-transform: uppercase;
          margin-left: 10px;
        }
      }

      ul {
        position: absolute;
        top: 60px;
        padding-left: 30px;
        font-family: "Anybody-SemiBold";
        text-transform: uppercase;
        li {
          list-style: none;
          margin: 20px 0px;
          cursor: pointer;
          color: rgba($dark, 0.25);
          @include transition;
          &:hover {
            color: rgba($dark, 0.8);
          }
          &.is-active {
            color: $primary;
          }
          .puce {
            @include transition;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            opacity: 0;
            transform: translate(-55px , 5px);
            background-color: $primary;
            &.is-active {
              transform: translate(-20px , 5px);
              opacity: 1;
            }
          }
          a {
            text-decoration: none;
          }
        }
      }

      h2 {
        font-family: "AnyBody-SemiBold";
        color: rgba($dark , 0.3);
        border-bottom: 2px solid transparent;
        margin-right: 20px;
        cursor: pointer;
        @include transition;
        &:hover {
          border-bottom: 2px solid $primary;
        }
        &.active {
          color: $dark;
          border-bottom: 2px solid $primary;
        }
      }
      p {
        color: $primary
      }
    }
  }
  .container {
    width: 100%;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    scroll-behavior: smooth;
    .content {
      max-width: 800px;
      width: 70%;
      margin-top: 75px;
    }
  }
}


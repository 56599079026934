@import '../../../styles/variables.scss';

a {
  text-decoration: none;
}
 
.container {
  animation: fadeIn 0.3s linear;
  animation-fill-mode: both;
  a {
    @include transition;
    display: inline-block;
    background-color: #E58A6D;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 6px 10px;
    color: $white;
    margin: 2px 0px;
    border-radius: $radius;
    font-size: 14px;
    min-width: 1px;
    box-shadow: 0px 8px 10px rgb(199, 199, 199);
    &:hover{
      min-width: 700px;
      background-color: #c4684c;
      box-shadow: 0px 8px 15px rgb(199, 199, 199);
    }
  }
  &.open {
    a {
      background-color: #6DE5BA;
      &:hover{
        background-color: #4ec299;
      }
    }
  }
 
}


// Keyframe animation
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(60px)
  }
  100% {
    opacity: 1;
    transform: translateY(0px)
  }
}